import React from 'react';
// import Link from '@material-ui/core/Link';
// import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
import Title from './Title';
import {
  PieChart, Pie, Cell,
} from 'recharts';

// const useStyles = makeStyles({
//   depositContext: {
//     flex: 1,
//   },
// });

const data = [
  { name: 'Group A', value: 500 },
  { name: 'Group B', value: 100 },
  { name: 'Group C', value: 600 },
  { name: 'Group D', value: 200 },
];

const COLORS = ['#3f51b5', '#00C49F', '#FFBB28', '#FF8042'];

export default function Deposits() {
  // const classes = useStyles();
  return (
    <React.Fragment>
      <Title>Sales by Region</Title>
      <PieChart width={200} height={200}>
        <Pie
          data={data}
          cx={100}
          cy={75}
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
        >
          {
            data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
          }
        </Pie>
      </PieChart>
    </React.Fragment>
  );
}

import React, { Component } from 'react';
import './app.scss';
import { Content } from 'carbon-components-react/lib/components/UIShell';
import PortfolioHeader from './components/PortfolioHeader';
import { Route, Switch } from 'react-router-dom';
import LandingPage from './content/LandingPage';
// import SampleAnalyticsPage from './content/SampleAnalyticsPage';
import Dashboard from './content/SampleAnalyticsPage'
import Footer from './content/LandingPage/Footer';
import OrdersDashboard from "./content/SampleAnalyticsPage/OrdersDashboard";
import SampleBusinessPage from "./content/SampleBusinessSite/SampleBusinessPage";

class App extends Component {
  render() {
    return (
      <>
        <PortfolioHeader />
        <Content>
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route path="/analytics" component={Dashboard} />
            <Route path="/orders" component={OrdersDashboard} />
            <Route path="/sampleBusiness" component={SampleBusinessPage} />
          </Switch>
        </Content>
      <Footer />
      </>
    );
  }
}

export default App;

import React from 'react';
import {
  Header,
  HeaderContainer,
  HeaderMenuButton,
  HeaderName,
  HeaderNavigation,
  HeaderMenuItem,
  HeaderSideNavItems,
  SideNav,
  SideNavItems,
  SkipToContent,
} from 'carbon-components-react/lib/components/UIShell';
import { Link } from 'react-router-dom';

const PortfolioHeader = () => (
  <div>
    <HeaderContainer
      render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <>
          <Header aria-label="Kevin D Humphries Portfolio Site">
            <SkipToContent />
            <HeaderMenuButton
              aria-label="Open menu"
              onClick={onClickSideNavExpand}
              isActive={isSideNavExpanded}
            />
            <HeaderName element={Link} to="/" prefix="">
              Overview
            </HeaderName>
            <HeaderNavigation aria-label="Analytics Sample">
              <HeaderMenuItem element={Link} to="/analytics">
                Analytics Sample
              </HeaderMenuItem>
            </HeaderNavigation>
            {/*<HeaderNavigation aria-label="Analytics Sample">*/}
            {/*  <HeaderMenuItem element={Link} to="/sampleBusiness">*/}
            {/*    Business Sample*/}
            {/*  </HeaderMenuItem>*/}
            {/*</HeaderNavigation>*/}
            <SideNav
              aria-label="side navigation"
              expanded={isSideNavExpanded}
              isPersistent={false}>
              <SideNavItems>
                <HeaderSideNavItems>
                  <HeaderMenuItem element={Link} to="/analytics">
                    Analytics Sample
                  </HeaderMenuItem>
                </HeaderSideNavItems>
              </SideNavItems>
            </SideNav>
          </Header>
          </>
      )}
    />
  </div>
);

export default PortfolioHeader;

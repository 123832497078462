import React, { Component } from 'react';
import Header from './Header';
import Skills from './Skill/Skills';
//import Footer from './Footer';

//const Landing = () => (
//    <div>
//        <main className="my-5">
//            <Skills />
//        </main>
//    </div>
//);

export default class LandingPage extends Component {

    constructor() {
        super();

        this.state = {
            title: 'Kevin D Humphries'
        };
    }

    render() {
        return (
          <div className="landing-page_title">
            <Header title={this.state.title} />
            <main className="my-5">
              <Skills />
            </main>
          </div>
        );
    }
}

import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
// import PeopleIcon from '@material-ui/icons/People';
// import BarChartIcon from '@material-ui/icons/BarChart';
// import LayersIcon from '@material-ui/icons/Layers';
// import AssignmentIcon from '@material-ui/icons/Assignment';
// import LandingPage from "../LandingPage";
// import Dashboard from "./index";
// import OrdersDashboard from "./OrdersDashboard";


const useStyles = makeStyles({
  listLink: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
});



export default function MainListItems () {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div>
        <Link className={classes.listLink} to="/analytics">
          <ListItem button>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        </Link>
        <Link className={classes.listLink} to="/orders">
          <ListItem button>
            <ListItemIcon>
              <ShoppingCartIcon />
            </ListItemIcon>
            <ListItemText primary="Orders" />
          </ListItem>
        </Link>
        {/*<ListItem button>*/}
        {/*  <ListItemIcon>*/}
        {/*    <PeopleIcon />*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText primary="Customers" />*/}
        {/*</ListItem>*/}
        {/*<ListItem button>*/}
        {/*  <ListItemIcon>*/}
        {/*    <BarChartIcon />*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText primary="Reports" />*/}
        {/*</ListItem>*/}
        {/*<ListItem button>*/}
        {/*  <ListItemIcon>*/}
        {/*    <LayersIcon />*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText primary="Integrations" />*/}
        {/*</ListItem>*/}
      </div>
    </React.Fragment>
  )
}
//
//
// export const secondaryListItems = (
//   <div>
//     <ListSubheader inset>Saved reports</ListSubheader>
//     <ListItem button>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Current month" />
//     </ListItem>
//     <ListItem button>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Last quarter" />
//     </ListItem>
//     <ListItem button>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Year-end sale" />
//     </ListItem>
//   </div>
// );
